import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import {
  sectorCategoories,
  servicesCategories,
  decodeEntities,
  sectorParentId,
  servicesParentId,
} from "../../../utils/helpers";
//import '../PostListNavigation.scss'

export const ProjectListNavigation = ({ categories }) => {
  const [location, setLocation] = useState("");
  const [serviceFilters, setServiceFilters] = useState([]);
  const [industryFilters, setIndustryFilters] = useState([]);

  useEffect(() => {
    const pathName = window?.location?.pathname
      ?.split("/")
      .filter((item) => item !== "" && item);
    setLocation(pathName[1]);
    setIndustryFilters(getCategoriesForParent(categories, sectorParentId));
    setServiceFilters(getCategoriesForParent(categories, servicesParentId));
  }, [categories]);

  const handleChange = (path) => {
    if (!path) return null;
    if (path === "projects") {
      navigate(`/projects/`);
    } else {
      navigate(`/project-category/${path}/`);
    }
  };

  const getCategoriesForParent = (categories, parentId) => {
    return categories.filter(
      (category) => category.node.wordpress_parent === parentId
    );
  };

  // returns the service category if multiple categories are outputted
  // const getNavigationFilters = (types, categoryArr) => {
  //   let finalCategories = [];
  //   for (let i = 0; i < types.length; i++) {
  //     const element = types[i];
  //     if (categoryArr.indexOf(element.node.wordpress_id) > -1) {
  //       finalCategories.push(element);
  //     }
  //   }
  //   return finalCategories;
  // };

  return (
    <>
      <section className="post-list-navigation">
        <div className="filter-container">
          <label className="filter-label" htmlFor="filter-service">
            Sector:
          </label>
          <select
            className="filter-select"
            name="filter-industry"
            id="filter-industry"
            value={location}
            onChange={(e) => handleChange(e.target.value)}
          >
            <option value="projects">All</option>
            {industryFilters &&
              industryFilters.map(
                ({ node: category }, index) =>
                  category.slug !== "uncategorised" && (
                    <option key={index} value={category.slug}>
                      {decodeEntities(category.name)}
                    </option>
                  )
              )}
          </select>
        </div>

        <div className="filter-container">
          <label className="filter-label" htmlFor="filter-industry">
            Service:
          </label>
          <select
            className="filter-select"
            name="filter-service"
            id="filter-service"
            value={location}
            onChange={(e) => handleChange(e.target.value)}
          >
            <option value="projects">All</option>
            {serviceFilters &&
              serviceFilters.map(
                ({ node: category }, index) =>
                  category.slug !== "uncategorised" && (
                    <option key={index} value={category.slug}>
                      {decodeEntities(category.name)}
                    </option>
                  )
              )}
          </select>
        </div>
      </section>
    </>
  );
};
