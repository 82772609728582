import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import "./ProjectList.scss";
import RenderContent from "../../RenderContent";
import { Pagination } from "../../Pagination";
import { Image } from "../../Image";
import { PostListItem } from "../../Blog/PostListItem";
import { ProjectListNavigation } from "../ProjectListNavigation";

export const ProjectList = ({ posts, title, pageContext, categories }) => {
  return (
    <section className="project-list">
      <div className="wrap">
        <ProjectListNavigation categories={categories.edges} />
        <div className="project-list-grid">
          {Object.keys(posts).length > 0 &&
            posts?.map((post, index) => {
              return (
                !post.node.slug.includes("gatsby") && (
                  <PostListItem
                    key={index}
                    data={post.node}
                    categories={categories}
                    showMedia
                  />
                )
              );
            })}
        </div>
        <Pagination pageContext={pageContext} />
      </div>
    </section>
  );
};

ProjectList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export const pageQuery = graphql`
  fragment ProjectListFields on wordpress__wp_projects {
    id
    project_category
    author {
      name
      path
      avatar_urls {
        wordpress_48
      }
    }
    title
    excerpt
    content
    date(formatString: "MMMM Qo gggg")
    slug
    path
    wordpress_id
    acf {
      layout: layout_projects {
        ...PostInformationQuery
      }
    }
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`;
