import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { ProjectList } from "../../Projects/ProjectList";
import { PostList as BlogList } from "../../Blog/PostList";

export const PostList = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          projectCategories: allWordpressWpProjectCategory(
            filter: { count: { gt: 0 } }
          ) {
            edges {
              node {
                name
                wordpress_id
                wordpress_parent
                slug
                path
              }
            }
          }
          postCategories: allWordpressCategory(filter: { count: { gt: 0 } }) {
            edges {
              node {
                name
                wordpress_id
                wordpress_parent
                slug
                path
              }
            }
          }
        }
      `}
      render={(data) => {
        if (props.postType === "news") {
          return <BlogList categories={data.postCategories} {...props} />;
        } else if (props.postType === "projects") {
          return <ProjectList categories={data.projectCategories} {...props} />;
        }
      }}
    />
  );
};
