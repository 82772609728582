import React from "react";
import "./ContentAndIconCarousel.scss";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import { Carousel } from "../../Carousel";

export const ContentAndIconCarousel = ({
  cards,
  content,
  contentBackground = "primary",
  //cardColour = "lightGrey",
  contentWrap = "normal",
}) => {
  const swiperSettings = {
    id: "related-carousel",
    slidesPerView: 1,
    spaceBetween: 0,
    grabCursor: false,
    pagination: false,
    navigation: true,
    speed: 2000,
    autoplay: {
      delay: 5000,
    },
    breakpoints: {
      540: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      1500: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
  };

  if (!cards) return null;

  return (
    <section className={`content-icon-carousel ${contentBackground}-background`}>
      <div className="wrap">
        <div className="col col--text">
          {content && (
            <RenderContent
              content={content}
              className={contentWrap === "small" ? "wrap-small" : ""}
            />
          )}
        </div>
        <div className="col col--carousel">
          <Carousel swiperSettings={swiperSettings}>
            {Object.keys(cards).length > 0 &&
              cards?.map((card, index) => {
                return (
                  <div className={`card`} key={index}>
                    {card.icon && (
                      <div className="icon-container">
                        <ImagePass src={card.icon} lazy={false} />
                      </div>
                    )}

                    <RenderContent content={card.content} />
                  </div>
                );
              })}
          </Carousel>
        </div>
      </div>
    </section>
  );
};
